import * as React from 'react';
import { Helmet } from 'react-helmet';


const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const iframeStyles = {
  bottom: '0',
  background: 'black',
  left: '0',
  position: 'absolute',
  right: '0',
  top: '0',
};

// markup
const LeaseBMTraining = () => {
  return (
    <main style={pageStyles}>
      <Helmet
        title='Koalafi Demo - Lease, Brick and Mortar - Koalafi'
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />

      <iframe
        style={iframeStyles}
        width="100%"
        height="100%"
        src="https://www.figma.com/embed?embed_host=share&#038;url=https://www.figma.com/proto/8bAXYqxBm7rciR0HqZZcW7/Koalafi-Lease-B-and-M-Training-Demo?node-id=66%3A33&#038;scaling=scale-down-width&#038;page-id=0%3A1&#038;starting-point-node-id=66%3A33&#038;hide-ui=1"
        allowFullScreen
      />
    </main>
  );
};

export default LeaseBMTraining;